/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'file-spreadsheet': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 2a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H4a2 2 0 01-2-2zm2-1a1 1 0 00-1 1v4h10V2a1 1 0 00-1-1zm9 6h-3v2h3zm0 3h-3v2h3zm0 3h-3v2h2a1 1 0 001-1zm-4 2v-2H6v2zm-4 0v-2H3v1a1 1 0 001 1zm-2-3h2v-2H3zm0-3h2V7H3zm3-2v2h3V7zm3 3H6v2h3z"/>',
    },
});
